import SidenavToggle from './buttons/SidenavToggle';
import Logo from '../../../assets/navbar-banner.jpeg';
import { Link, Typography } from '@mui/material';
import { MdOutlineEmail } from "react-icons/md";

export default function Navbar() {
  return (
    <header className="flex min-h-16 items-center w-full box-border shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)] z-40">
        <nav className="flex items-center box-border w-full pl-2 pr-4">
            <div className="flex flex-1 items-center box-border">
                <SidenavToggle />
                <Link href="/" sx={{ml: '0.75rem'}}>
                    <img src={Logo} alt="Logo" className="h-8" />
                </Link>
            </div>
            <div className="flex flex-1 items-center justify-end box-border gap-2">
                <Link
                    href="mailto:contact@synctivate.com"
                    sx={{
                        color: 'rgb(66 136 164)',
                        fontSize: '13px',
                        textTransform: 'none',
                        textDecoration: 'none',
                        display: 'flex',
                        alignItems: 'center',
                        '&:hover': {
                            color: 'rgb(57 90 138)',
                        },
                    }}
                >
                    <MdOutlineEmail className='h-4 w-4' />
                    <Typography sx={{fontSize: '13px', ml: '0.25rem', lineHeight: 'normal'}}>contact@synctivate.com</Typography>
                </Link>
            </div>
        </nav>
    </header>
  );
}