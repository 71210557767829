import { useContext, useState, useEffect } from "react";
import SidenavButton from "./buttons/SidenavButton";
import { Typography, Divider } from "@mui/material";
import { IoCalendarClearOutline, IoMapOutline } from "react-icons/io5";
import { IoIosPaper } from "react-icons/io";
import { TbClockPlus } from "react-icons/tb";
import { MdAddBusiness, MdDataThresholding } from "react-icons/md";
import HomeContext from "../context/HomeContext";

export default function Sidenav() {
    
    const { sidenavOpen } = useContext(HomeContext);
    const [onePagers, setOnePagers] = useState([]);

    useEffect(() => {

        async function fetchOnePagers() {
            const response = await fetch('https://api.quickbase.com/v1/records/query',
                {
                method: 'POST',
                headers: {
                    'QB-Realm-Hostname': 'synctivate.quickbase.com',
                    'Authorization': 'QB-USER-TOKEN b7nsa7_nyiv_0_bh86apvb5626whctbxrrc4t4bqa',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "from": "bt9cqxzmc",
                    "select": [
                        3, // Record ID
                        6, // Industry
                        7, // Description
                        15, // iFrame
                    ],
                    "where": "{3.GT.0}",                  
                })
            })
            const data = await response.json();
            console.log(data);
            setOnePagers(data?.data);
        }

        fetchOnePagers();
        
    }
    , []);

    if (!sidenavOpen) {
        return null;
    }

    return (
        <div className="min-w-[300px] w-full md:max-w-[300px] border-r border-gray-200 p-3 bg-white h-full fixed md:relative top-16 md:top-0 left-0 z-50 md:z-0">
            <div className="flex flex-col w-full">
                <Typography sx={{fontWeight: "600", color: "rgb(51,51,51)", fontSize: '15px', mb: '0.5rem'}}>Tools</Typography>
                <SidenavButton
                    onClick={() => { window.open('/form-demo', '_blank') }}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <MdAddBusiness className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Custom Forms</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('/dashboard/create/','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <MdDataThresholding className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Custom Dashboards</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('/routing/','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <IoMapOutline className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Route Planner</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('https://www.youtube.com/watch?v=0pUOoffr2Tg&feature=youtu.be','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <IoCalendarClearOutline className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Sync Scheduler/Routing Combo</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('/organizer','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <IoCalendarClearOutline className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Task Organizer</Typography>
                </SidenavButton>
                <SidenavButton
                    onClick={()=> {window.open('/timecards/create/','_blank')}}
                >
                    <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                        <TbClockPlus className='text-white h-4 w-4' />
                    </div>
                    <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>Timesheet Entry</Typography>
                </SidenavButton>
            </div>
            <Divider sx={{my: '1rem'}} />
            <div className="flex flex-col w-full">
                <Typography sx={{fontWeight: "600", color: "rgb(51,51,51)", fontSize: '15px', mb: '0.5rem'}}>One-Pagers</Typography>
                {onePagers?.map((onePager) => (
                    <SidenavButton
                        key={onePager['3'].value}
                        onClick={() => {window.open(`/one-pagers/${onePager['3'].value}`, '_blank')}}
                    >
                        <div className="h-6 w-6 rounded-md bg-[#166EE1] flex items-center justify-center">
                            <IoIosPaper className='text-white h-4 w-4' />
                        </div>
                        <Typography sx={{fontSize: '13px', fontWeight: '500', color: 'rgb(51,51,51)', lineHeight: 'normal', ml: '0.5rem'}}>{onePager['6'].value}</Typography>
                    </SidenavButton>
                ))}
            </div>
        </div>
    );
}