import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Navbar from '../home/components/Navbar';
import Sidenav from '../home/components/Sidenav';

export default function OnePager() {
    const { onePagerName } = useParams();
    const [fileUrl, setFileUrl] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchOnePager() {
            try {
                const url = `/files/${onePagerName}`;
                console.log(`Making request to: ${url}`);
                const response = await fetch(url);

                if (response.status === 200) {
                    setFileUrl(url); // Set the URL to the file
                } else {
                    console.error(`No file found for ID: ${onePagerName}`);
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false); // Update loading state
            }
        }

        fetchOnePager();
    }, [onePagerName]);

    return (
        <div className='absolute w-full h-full flex flex-col overflow-hidden'>
            <Navbar />
            <div className="flex flex-row flex-1 overflow-hidden">
                <Sidenav />
                <div className="flex flex-col flex-1 overflow-y-auto">
                    {loading ? (
                        <p>Loading...</p>
                    ) : fileUrl ? (
                        <>
                            {console.log(`Displaying file from URL: ${fileUrl}`)}
                            <iframe 
                                src={`/files/${onePagerName}`}
                                style={{ width: '100%', height: 'calc(100vh - 60px)', border: 'none' }} 
                                title="One Pager Content"
                            />
                        </>
                    ) : (
                        <p>No file found for the given ID or loading error occurred.</p>
                    )}
                </div>
            </div>
        </div>
    );
}
