import Navbar from "../home/components/Navbar";
import Sidenav from "../home/components/Sidenav";

export default function Routing() {
    return (
        <div className='absolute w-full h-full flex flex-col overflow-hidden'>
            <Navbar />
            <div className="flex flex-row flex-1 overflow-hidden">
                <Sidenav />
                <div className="flex flex-col flex-1 overflow-y-auto">
                    <iframe src="https://synctivate.quickbase.com/db/btye8buzj?a=dbpage&pageID=2" title="Routing Tool" className="w-full h-full" />
                </div>
            </div>
        </div>
    )
}