import RoutingPreview from '../../assets/routing-preview.png';
import OrganizerPreview from '../../assets/organizer-preview.png';
import DashboardPreview from '../../assets/dashboard-preview.png';
import TimesheetPreview from '../../assets/timesheet-preview.png';
import CompanyFormPreview from '../../assets/company-form-preview.png';
import SchedulerPreview from '../../assets/scheduler-preview.png';
import { useNavigate } from "react-router-dom";

// Move DemoCard inside IntegrationSection.js
function DemoCard({ title, description, preview, onClick }) {
    return (
        <div 
            onClick={onClick}
            className="
                flex 
                flex-col 
                shadow-[0_0_1px_rgba(0,0,0,0.32),0_0_2px_rgba(0,0,0,0.08),0_1px_3px_rgba(0,0,0,0.08)] 
                hover:shadow-[0_0_1px_rgba(0,0,0,0.2),0_0_2px_rgba(0,0,0,0.08),0_3px_4px_rgba(0,0,0,0.03),0_3px_16px_rgba(0,0,0,0.06)] 
                cursor-pointer 
                rounded-md
            "
        >
            <img src={preview} alt={`${title} Preview`} className="w-full h-48 object-cover rounded-t-md border-b border-gray-200" />
            <div className="p-4 flex flex-col">
                <h1 className="mb-3 text-[22px] font-semibold">{title}</h1>
                <p className="text-base">{description}</p>
            </div>
        </div>
    );
}

export default function IntegrationSection() {
    const navigate = useNavigate();
    
    return (
        <div className='flex flex-col flex-1 pt-8 pb-4 px-12'>
            <h1 className="text-2xl font-bold mb-4 mx-auto">Custom Tools</h1>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full py-4">
                <DemoCard 
                    onClick={() => {navigate('/form-demo')}}
                    preview={CompanyFormPreview}
                    title={'Custom Forms'}
                    description={'Enter data on cleaner, custom forms that sync directly with your app.'}
                />
                <DemoCard 
                    onClick={()=> {window.open('/dashboard/create/', '_self')}}
                    preview={DashboardPreview}
                    title={'Custom Dashboards'}
                    description={'Easily visualize data from any source.'}
                />
                <DemoCard 
                    onClick={()=> {window.open('/routing/', '_blank')}}
                    preview={RoutingPreview}
                    title={'Route Planner'}
                    description={'Plan your routes and optimize your travel time.'}
                />
                <DemoCard 
                    onClick={()=> {window.open('https://www.youtube.com/watch?v=0pUOoffr2Tg&feature=youtu.be','_blank')}}
                    preview={SchedulerPreview}
                    title={'Sync Scheduler/Routing Combo'}
                    description={'Custom Scheduling and Routing UI with Map Integration.'}
                />
                <DemoCard 
                    onClick={()=> {window.open('/organizer','_blank')}}
                    preview={OrganizerPreview}
                    title={'Task Organizer'}
                    description={'Effortlessly organize tasks based on priority and team availability.'}
                />
                <DemoCard 
                    onClick={()=> {window.open('/timecards/create/','_blank')}}
                    preview={TimesheetPreview}
                    title={'Timesheet Entry'}
                    description={'Streamline payroll with our bulk timesheet entry tool, enabling quick, one-click submission to your time tracking system.'}
                />
            </div>
        </div>
    );
}
