import { Routes, Route } from "react-router-dom"
import SchedulerDemo from '../features/scheduler/components/SchedulerDemo';
import Home from "../features/home/components/Home";
import CompanyForm from "../features/formdemo/components/CompanyForm";
import Routing from "../features/routing/Routing";
import OnePager from "../features/one-pagers/OnePager";
import PageTitle from "./PageTitle";
import { usePageTracking } from "../utils/tracking";
import HomeProvider from "../features/home/context/HomeProvider";

function App() {
  usePageTracking();
  return (
    <HomeProvider>
      <Routes>
        <Route 
        path="/" 
        element={
          <>
            <PageTitle title="Home" />
            <Home />
          </>
        } 
      />
        <Route 
        path="/routing" 
        element={
          <>
            <PageTitle title="Route Planner" />
            <Routing />
          </>
        } 
      />
        <Route 
        path="/organizer" 
        element={
          <>
            <PageTitle title="Task Organizer" />
            <SchedulerDemo />
          </>
        } 
      />
        <Route 
        path="/form-demo" 
        element={
          <>
            <PageTitle title="Custom Forms" />
            <CompanyForm />
          </>
        } 
      />
        <Route 
        path="/one-pagers/:onePagerName" 
        element={
          <>
            <PageTitle title="One Pager" />
            <OnePager />
          </>
        }
       />
      </Routes>
    </HomeProvider>
  );
}

export default App;