import Navbar from "./Navbar"
import Sidenav from "./Sidenav";
import IntegrationSection from "./sections/IntegrationSection"
import IndustryOnePagersSection from "./sections/IndustryOnePagersSection";

export default function Home() {
    return (
        <div className='absolute w-full h-full flex flex-col overflow-hidden'>
            <Navbar />
            <div className="flex flex-row flex-1 overflow-hidden">
                <Sidenav />
                <div className="flex flex-col flex-1 overflow-y-auto">
                    <IntegrationSection />
                    <div className="my-12" /> {/* Adds more space */}
                    <IndustryOnePagersSection />
                </div>
            </div>
        </div>
    )
}