import { useState, useEffect } from "react"
import MultiPageForm from "./MultiPageForm";
import CompanyDetails from "./CompanyDetails";
import ContactsPage from "./ContactsPage";
import Loading from "../../shared/components/Loading";
import AlertMessage from "../../shared/components/AlertMessage";
import Navbar from "../../home/components/Navbar";
import Sidenav from "../../home/components/Sidenav";

export default function CompanyForm() {
    const [submitting, setSubmitting] = useState(false)
    const [companyData, setCompanyData] = useState({
        company_name: "",
        address_street: "",
        address_street_2: "",
        address_city: "",
        address_state: "",
        address_zip: "",
        phone1: "",
        phone2: "",
        email: "",
        website: "",
    })
    const [contacts, setContacts] = useState([])
    const [message, setMessage] = useState('')

    const submitForm = () => {
        setSubmitting(true)
    }
    useEffect(() => {
        if (submitting) {
            console.log(companyData)
            contacts.forEach(contact => {
                contact.rid = contact.id + 1
                console.log(contact)
            })
            const timer = setTimeout(() => {
                setSubmitting(false)
                setContacts([])
                setMessage(`Company ${companyData.company_name} and ${contacts.length} contacts added to your app`)
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [submitting, companyData, contacts])

    return (
        <div className='absolute w-full h-full flex flex-col overflow-hidden'>
            <AlertMessage message={message} setMessage={setMessage} />
            {submitting && <Loading loading={submitting} />}
            <Navbar />
            <div className="flex flex-row flex-1 overflow-hidden">
                <Sidenav />
                <div className="flex flex-col flex-1 overflow-y-auto">
                    <MultiPageForm label={"Company Form"} initialStep={1} totalSteps={2} onSubmit={submitForm}>
                        <CompanyDetails
                            companyData={companyData}
                            setCompanyData={setCompanyData}
                        />
                        <ContactsPage
                            contacts={contacts}
                            setContacts={setContacts}
                        />
                    </MultiPageForm>
                </div>
            </div>
        </div>
    )
}



